import React from "react";

function Tentang(props) {
  const {
    nama,
    pekerjaan,
    biografi_text_1,
    biografi_text_2,
    biografi_text_3,
    biografi_text_4,
    biografi_text_5,
    // gambar_1,
  } = props;
  return (
    <>
      <div className="flex justify-center items-center ">
        <div className="max-w-md mx-auto  rounded-xl overflow-hidden sm:max-w-2xl mb-4"> {/* bg-gray-800*/}
          <div className="md:flex">
            <div className="">
              <div className="tracking-wide text-3xl text-[#00df9a] font-extrabold">
                {nama}
              </div>
              <div className="block mt-1 text-xl leading-tight font-medium text-gray-400">
                {pekerjaan}
              </div>
              <p className="mt-2 text-justify text-gray-400 list-decimal dark:lg:text-2xl  dark:text-gray-300 py-4 dark:font-semibold">
                {biografi_text_1}
              </p>
            </div>
            {/* <div className="md:flex-shrink-0">
              <img
                className="h-48 w-full object-cover md:h-full md:w-48 rounded-xl"
                src={gambar_1}
                alt=""
              />
            </div> */}
          </div>
        </div>
      </div>

      <div className="flex justify-center items-center  ">
        <div className="max-w-md mx-auto  rounded-xl overflow-hidden sm:max-w-2xl mb-4"> {/* bg-gray-800*/}
          <div className="">
            <p className="text-justify text-gray-400 list-decimal dark:lg:text-2xl  dark:text-gray-300 py-4 dark:font-semibold">
              {biografi_text_2}
            </p>
          </div>
          <div className="pt-4">
            <p className="text-justify text-gray-400 list-decimal dark:lg:text-2xl  dark:text-gray-300 py-4 dark:font-semibold">
              {biografi_text_3}
            </p>
          </div>
          <div className="pt-4">
            <p className="text-justify text-gray-400 list-decimal dark:lg:text-2xl  dark:text-gray-300 py-4 dark:font-semibold">
              {biografi_text_4}
            </p>
          </div>
          <div className="py-4">
            <p className="text-justify text-gray-400 list-decimal dark:lg:text-2xl  dark:text-gray-300 py-4 dark:font-semibold">
              {biografi_text_5}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Tentang;
